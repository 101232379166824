.input-group {
    position: relative;
  }
  
  .input-group-append {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 5px;
    z-index: 1;
  }
  .chat-item {
    margin-bottom: 1rem; 
}

.chat-title {
    margin-top: 0.5rem;
    color: #ffffff; 
}