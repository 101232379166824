.form-check-input:focus {
   background-color: #198754!important; 
  }
  .idnocolor {
   background-color: #b9c6c0;
   border-radius: 3px;
   text-align: center;
   padding: 2px !important;
  }
  .ans_optiontitle {
   font-weight: 600;
   padding-right: 4px;
 }
 .idnocolor1 {
   background-color: #b9c6c0;
   border-radius: 3px;
   display: flex;
   padding: 2px !important;
   margin-left: 10px;
   
  }
  
  .ques_img img {
    width: 150px;
    height: 150px;
   
  }
 
  .options_img img {
    width: 150px;
    height: 150px;
   
  }
  .idnocolor2 {
    background-color: #b9c6c0;
    border-radius: 3px;
   
    padding: 2px !important;
    
   }
   .option_spac {
    margin-right: 10px;
   }
   .edit_tab {
    cursor: pointer;
   }