
.filelabel1 {
    width: 90%;
    height: 150px;
    border: 2px solid #EDEDED;
    border-radius: 5px;
    display: block;
    padding: 5px;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;
   
}


.filelabel1 .title1 {
    padding-top: 100px;
    margin-top: 250px;
    color:#8A8A8A ;
    transition: 200ms color;
}

.filelabel1:hover .title1 {
  color: #8A8A8A;
}
#FileInput1{
    display:none;
}
    