.Main {
  background-color: #efefef;
  width: "25%";
  border: 0;
  color: #8a8a8a;
  outline: none;
  margin-top: 5px;
}
.filelabel {
  width: 90%;
  height: 150px;
  border: 1px dashed #1665c4;
  border-radius: 5px;
  display: block;
  padding: 5px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 10px;
}
.filelabel i {
  display: block;
  font-size: 30px;
  padding-bottom: 5px;
}
.filelabel i,
.filelabel .title {
  padding-top: 15px;
  margin-top: 100px;
  color: #1665c4;
  transition: 200ms color;
}

.filelabel:hover i,
.filelabel:hover .title {
  color: #1665c4;
}
#FileInput {
  display: none;
}

.custom-checkbox-label {
  position: relative;
  /* padding-left: 25px; */
  cursor: pointer;
}

.custom-checkbox-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  /* border: 2px solid #000; */
  border-radius: 3px;
}

.custom-checkbox-label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 6px;
  width: 8px;
  height: 14px;
  /* border: solid #000; */
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  display: none;
}

.custom-checkbox-label input:checked + span:before {
  background-color: #000;
  border-color: #000;
}

.custom-checkbox-label input:checked + span:after {
  display: block;
}
