
  .modal-backdrop.show {
    opacity: 0!important;
}

@keyframes gradientBorder {
  0% {
    border-image-source: linear-gradient(90deg, #333333, #555555, #777777, #999999);
  }
  25% {
    border-image-source: linear-gradient(90deg, #555555, #777777, #999999, #bbbbbb);
  }
  50% {
    border-image-source: linear-gradient(90deg, #777777, #999999, #bbbbbb, #dddddd);
  }
  75% {
    border-image-source: linear-gradient(90deg, #999999, #bbbbbb, #dddddd, #ffffff);
  }
  100% {
    border-image-source: linear-gradient(90deg, #bbbbbb, #dddddd, #ffffff, #333333);
  }
}

.styled-heading {
  border: 4px solid;
  border-image-slice: 1;
  padding: 10px;
  border-radius: 5px;
  animation: gradientBorder 3s infinite;
  font-size: 25px;
  font-weight: bold;
  background-color: #c5b8b857;
}
